import moment from 'moment';

export function getAuthorityByToken() {
  const str = localStorage.getItem('api-key');
  if (!str) {
    return ['guest'];
  }
  const key = JSON.parse(str);
  const expires = localStorage.getItem('api-token-expires');
  if (moment(expires).isBefore(moment(new Date()))) {
    return ['guest'];
  }
  // let role = 'user';
  // if (token.user && token.user.parent_id) {
  //   role = 'subaccount';
  // }
  // console.log('role', role)
  return [key];
}

// use localStorage to store the authority info, which might be sent from server in actual project.
export function getAuthority() {
  return getAuthorityByToken();
  // return localStorage.getItem('antd-pro-authority') || ['admin', 'user'];
  // c1onst authorityString =
  //   typeof str === 'undefined' ? localStorage.getItem('antd-pro-authority') : str;
  // // authorityString could be admin, "admin", ["admin"]
  // let authority;
  // try {
  //   authority = JSON.parse(authorityString);
  // } catch (e) {
  //   authority = authorityString;
  // }
  // if (typeof authority === 'string') {
  //   return [authority];
  // }

  // return authority || ['admin'];
}

/**
 * 判断当前用户是否具有指定权限
 * @param {string | string[]} authorityKey - 权限关键字或关键字数组
 * @param {string} relation - 判定关系，当 authorityKey 为数组时，默认为 'or'，authorityKey 中有一个 key 有权限就返回 true，可选值为 'or' 或 'and'
 * @returns {boolean} - 当前用户是否具有指定权限
 */
export function hasAuthority(authorityKey, relation = 'or') {
  const data = getAuthorityByToken();
  if (data.length === 0) {
    return false;
  }

  if (typeof authorityKey === 'string') {
    return data?.[0]?.includes(authorityKey);
  }

  if (Array.isArray(authorityKey)) {
    return (relation === 'or' ? authorityKey.some : authorityKey.every)(key =>
      data?.[0]?.includes(key)
    );
  }

  return false;
}

export function setAuthority(authority) {
  const proAuthority = typeof authority === 'string' ? [authority] : authority;
  return localStorage.setItem('antd-pro-authority', JSON.stringify(proAuthority));
}

export function setAuthorityByApi(key) {
  if (!key || JSON.stringify(key) === '{}') {
    localStorage.removeItem('api-key');
    return ['guest'];
  }
  localStorage.setItem('api-key', JSON.stringify(key));
  return [key];
}

export function setAuthorityByToken(token) {
  // console.log('token', token);
  if (!token || JSON.stringify(token) === '{}') {
    localStorage.removeItem('api-token');
    localStorage.removeItem('api-token-expires');
    return ['guest'];
  }
  localStorage.setItem('api-token', JSON.stringify(token));
  localStorage.setItem(
    'api-token-expires',
    moment()
      .add(token.expires_in, 's')
      .format()
  );
  // let role = 'user';
  // if (token.user && token.user.parent_id) {
  //   role = 'subaccount';
  // }
  return [];
  // return [role];
  // return localStorage.setItem('antd-pro-authority', JSON.stringify(proAuthority));
}

export function getToken() {
  const str = localStorage.getItem('api-token');
  if (!str) {
    return [];
  }
  const token = JSON.parse(str);
  const expires = localStorage.getItem('api-token-expires');
  if (moment(expires).isBefore(moment(new Date()))) {
    return [];
  }
  return token;
}

import { stringify } from 'qs';
import request from '@/utils/request';
import { formatTableParams } from '@/utils/utils';

export async function queryProjectNotice() {
  return request('/api/project/notice');
}

export async function queryActivities() {
  return request('/api/activities');
}

export async function queryRule(params) {
  return request(`/api/rule?${stringify(params)}`);
}

export async function removeRule(params) {
  return request('/api/rule', {
    method: 'POST',
    body: {
      ...params,
      method: 'delete',
    },
  });
}

export async function addRule(params) {
  return request('/api/rule', {
    method: 'POST',
    body: {
      ...params,
      method: 'post',
    },
  });
}

export async function updateRule(params) {
  return request('/api/rule', {
    method: 'POST',
    body: {
      ...params,
      method: 'update',
    },
  });
}

export async function fakeSubmitForm(params) {
  return request('/api/forms', {
    method: 'POST',
    body: params,
  });
}

export async function fakeChartData() {
  return request('/api/fake_chart_data');
}

export async function queryTags() {
  return request('/api/tags');
}

export async function queryBasicProfile() {
  return request('/api/profile/basic');
}

export async function queryAdvancedProfile() {
  return request('/api/profile/advanced');
}

export async function queryFakeList(params) {
  return request(`/api/fake_list?${stringify(params)}`);
}

export async function removeFakeList(params) {
  const { count = 5, ...restParams } = params;
  return request(`/api/fake_list?count=${count}`, {
    method: 'POST',
    body: {
      ...restParams,
      method: 'delete',
    },
  });
}

export async function addFakeList(params) {
  const { count = 5, ...restParams } = params;
  return request(`/api/fake_list?count=${count}`, {
    method: 'POST',
    body: {
      ...restParams,
      method: 'post',
    },
  });
}

export async function updateFakeList(params) {
  const { count = 5, ...restParams } = params;
  return request(`/api/fake_list?count=${count}`, {
    method: 'POST',
    body: {
      ...restParams,
      method: 'update',
    },
  });
}

export async function login(params) {
  return request('/api/oauth/token', {
    method: 'POST',
    body: {
      ...params,
    },
  });
}

export async function fakeRegister(params) {
  return request('/api/register', {
    method: 'POST',
    body: params,
  });
}

export async function queryNotices() {
  return request('/api/notices');
}

export async function clearNotices() {
  return request('/api/notices', {
    method: 'DELETE',
  });
}

export async function markNoticeRead(id) {
  return request(`/api/notices/${id}/read`, {
    method: 'PUT',
  });
}

export async function getFakeCaptcha(mobile) {
  return request(`/api/captcha?mobile=${mobile}`);
}

/**
 * Resource的查询接口
 */
export async function query(resource, params) {
  return request(`/api/${resource}?${stringify(params)}`, {
    method: 'GET',
  });
}

export async function queryAccounts(params) {
  return request(`/api/accounts`, {
    method: 'POST',
    body: params,
  });
}

export async function queryDistributionList(id) {
  return request(`/api/accounts/${parseInt(id, 10)}/users`, {
    method: 'GET',
  });
}

export async function setDistribution(id, params) {
  return request(`/api/accounts/${parseInt(id, 10)}/users`, {
    method: 'POST',
    body: params,
  });
}

export async function queryDisputesCustomer(id) {
  return request(`/api/accounts/${parseInt(id, 10)}/disputes`, {
    method: 'GET',
  });
}

export async function setDisputesCustomer(id, params) {
  return request(`/api/accounts/${parseInt(id, 10)}/disputes`, {
    method: 'POST',
    body: params,
  });
}
/**
 * Resource的添加接口
 */
export async function add(resource, params) {
  return request(`/api/${resource}`, {
    method: 'POST',
    body: {
      ...params,
    },
  });
}

/**
 * Resource的更新接口
 */
export async function update(resource, id, params) {
  return request(`/api/${resource}/${id}`, {
    method: 'PUT',
    body: {
      ...params,
    },
  });
}

/**
 * Resource的添加接口
 * @remark 如何支持批量删除?
 */
export async function del(resource, id) {
  return request(`/api/${resource}/${id}`, {
    method: 'DELETE',
  });
}

/**
 * 扩展接口，导出资源
 * @remark export是保留关键字，不能当函数名
 */

export async function exportResource(resource, params) {
  const q = { filter: params.filter, sort: params.sort };
  return request(`/api/${resource}/export?${stringify(q)}`, {
    method: 'POST',
    body: {
      ...params,
    },
  });
}

// export async function exportResource(id) {
//   // const q = { filter: params.filter, sort: params.sort };
//   return request(`/api/export_tasks/${parseInt(id, 10)}/download`, {
//     method: 'GET',
//   });
// }

export async function exportTask(params) {
  return request(`/api/disputes/export_tasks`, {
    method: 'POST',
    body: {
      ...params,
    },
  });
}

export async function exportList() {
  return request(`/api/disputes/export_tasks`, {
    method: 'GET',
  });
}

/**
 * 扩展接口，退款
 */
export async function refund(resource, id, params) {
  return request(`/api/${resource}/${id}/refund`, {
    method: 'POST',
    body: {
      ...params,
    },
  });
}

/**
 * 同步网关
 * @param id 网关ID
 */
export async function syncGateway(id) {
  return request(`/api/gateways/${id}/sync`, {
    method: 'POST',
  });
}

/**
 * 批量同步网关
 * @param id 网关ID
 */
export async function batchSyncGateway(params) {
  return request(`/api/accounts/setting`, {
    method: 'POST',
    body: {
      ...params,
    },
  });
}

/**
 * 接受争议
 * @param id 网关ID
 */
export async function replyDispute(id, params) {
  return request(`/api/disputes/${id}/reply`, {
    method: 'POST',
    body: {
      ...params,
    },
  });
}

/**
 * 接受争议
 * @param id 网关ID
 */
export async function accpetDispute(id, params) {
  return request(`/api/disputes/${id}/accept`, {
    method: 'POST',
    body: {
      ...params,
    },
  });
}

/**
 * 拒绝争议
 * @param id 网关ID
 */
export async function appealDispute(id, params) {
  return request(`/api/disputes/${id}/appeal`, {
    method: 'POST',
    body: {
      ...params,
    },
  });
}

/**
 * 客服管理 启用禁用
 * @param id 客服ID
 */
export async function toggleStatus(id) {
  return request(`/api/users/${id}/is_use`, {
    method: 'GET',
  });
}

/**
 * 客服详情删除负责网关账号
 * @param id 客服ID
 */

export async function detachUserAccount(id, params) {
  return request(`/api/users/${id}/account`, {
    method: 'POST',
    body: {
      ...params,
    },
  });
}

// 争议详情 添加跟踪信息 承运商
export async function carriers() {
  return request(`/api/config/carriers`, {
    method: 'GET',
  });
}

export async function defaultCarriers(id) {
  return request(`/api/disputes/${id}/get_tracking_number`, {
    method: 'GET',
  });
}

export async function replyTracking(id, params) {
  return request(`/api/disputes/${id}/provide_tracking_number`, {
    method: 'POST',
    body: params,
  });
}

export async function uploadTrackNo(id, params) {
  return request(`/api/disputes/${id}/upload-track-no`, {
    method: 'POST',
    body: params,
  });
}
// export async function replyMessage(id, params) {
//   return request(`/api/disputes/${id}/reply_message`, {
//     method: 'POST',
//     body: params,
//   });
// }

export async function replyMessage(id, params) {
  return request(`/api/paypal/dispute/${id}/reply-msg`, {
    method: 'POST',
    body: params,
  });
}

export async function transactionDetailQuery(id) {
  return request(`/api/transactions/${id}`, {
    method: 'GET',
  });
}

export async function transactionExportTask(params) {
  return request(`/api/transactions/export_tasks`, {
    method: 'POST',
    body: {
      ...params,
    },
  });
}

export async function auditExportTask(params) {
  return request(`/api/audits/export_tasks`, {
    method: 'POST',
    body: {
      ...params,
    },
  });
}

export async function disputeAssign(params) {
  return request(`/api/disputes/users`, {
    method: 'POST',
    body: {
      ...params,
    },
  });
}

export async function makeOfferPartRefund(id, params) {
  return request(`/api/paypal/dispute/${id}/request-refund-partly`, {
    method: 'POST',
    body: {
      ...params,
    },
  });
}

// 提交更多信息
export async function provideMoreInfo(id, params) {
  return request(`/api/paypal/dispute/${id}/more-info`, {
    method: 'POST',
    body: params,
  });
}
// paypal 争议同步
export async function disputeSync(params) {
  return request(`/api/disputes/sync`, {
    method: 'POST',
    body: {
      ...params,
    },
  });
}

export async function downloadAttach(params) {
  return request(`/api/attachment/download`, {
    method: 'POST',
    body: {
      ...params,
    },
  });
}

// 显示隐藏
export async function disputeIsShow(params) {
  return request(`/api/disputes/is_show`, {
    method: 'POST',
    body: {
      ...params,
    },
  });
}

// 拒绝索赔，提供信息  完善图片上传
export async function refuseSubmitInfo(id, params) {
  return request(`/api/paypal/dispute/${id}/refuse`, {
    method: 'POST',
    body: params,
  });
}

export async function acceptReturnRefund(id, params) {
  return request(`/api/paypal/dispute/${id}/accept-claim`, {
    method: 'POST',
    body: {
      ...params,
    },
  });
}

export async function acceptRefund(id, params) {
  return request(`/api/paypal/dispute/${id}/accept-refund`, {
    method: 'POST',
    body: {
      ...params,
    },
  });
}

export async function paypalNotice(id, params) {
  return request(`/api/paypal/dispute/${id}/acknowledge-return-item`, {
    method: 'POST',
    body: {
      ...params,
    },
  });
}
// 申诉
export async function appealSettlement(id, params) {
  return request(`/api/paypal/dispute/${id}/appeal`, {
    method: 'POST',
    body: params,
  });
}

// xb工单设置备注
export async function setXBNote(id, params) {
  return request(`/api/xb/dispute/${id}/note`, {
    method: 'POST',
    body: params,
  });
}

// saas工单设置备注
export async function setSaasNote(id, params) {
  return request(`/api/saas/dispute/${id}/note`, {
    method: 'POST',
    body: params,
  });
}

export async function generateAnytimeReport(params) {
  return request(`/api/reports/anytime`, {
    method: 'POST',
    body: {
      ...params,
    },
  });
}

export async function autoRefund(id) {
  return request(`/api/accounts/${id}/is_auto_refund`, {
    method: 'GET',
  });
}

export async function autoSync(id) {
  return request(`/api/accounts/${id}/is_auto_sync`, {
    method: 'GET',
  });
}

export async function autoReturnRefund(id) {
  return request(`/api/accounts/${id}/is_auto_return_refund`, {
    method: 'GET',
  });
}

export async function disputeBatchUser(params) {
  return request(`/api/disputes/batch_user`, {
    method: 'POST',
    body: {
      ...params,
    },
  });
}

export async function tackingInfo(id) {
  return request(`/api/transactions/tacking_info/${id}`, {
    method: 'GET',
  });
}

export async function addTackingInfo(params) {
  return request(`/api/transactions/tacking_info`, {
    method: 'POST',
    body: {
      ...params,
    },
  });
}

export async function editTackingInfo(id, params) {
  return request(`/api/transactions/tacking_info/${id}`, {
    method: 'PUT',
    body: {
      ...params,
    },
  });
}
// /api/transactions/tacking_info/{id}
export async function deleteTrackInfo(id) {
  return request(`/api/transactions/tacking_info/${id}`, {
    method: 'DELETE',
  });
}

// /api/transactions/tacking_info/clone
export async function cloneTackingInfo(params) {
  return request(`/api/transactions/tacking_info/clone`, {
    method: 'POST',
    body: {
      ...params,
    },
  });
}

export async function saleMailDetail(id) {
  return request(`/api/sale_mail/${id}`, {
    method: 'GET',
  });
}

// api/sale_mail/batch_send
export async function mailBatchSend(params) {
  return request(`/api/sale_mail/batch_send`, {
    method: 'POST',
    body: {
      ...params,
    },
  });
}

// 争议 审计 交易get->post
export async function disputesQuery(params) {
  const { simpleQuery, batchQuery } = params;
  const q = {
    filter: simpleQuery.filter,
    sort: simpleQuery.sort,
    page: simpleQuery.page,
    page_size: simpleQuery.page_size,
  };
  return request(`/api/disputes?${stringify(q)}`, {
    method: 'POST',
    body: {
      ...batchQuery,
    },
  });
}

export async function auditsQuery(params) {
  const { simpleQuery, batchQuery } = params;
  // const q = { filter: singleQuery.filter, sort: singleQuery.sort };
  return request(`/api/audits?${stringify(simpleQuery)}`, {
    method: 'POST',
    body: {
      ...batchQuery,
    },
  });
}

export async function transactionsQuery(params) {
  return request(`/api/transactions`, {
    method: 'POST',
    body: {
      ...params,
    },
  });
}

export async function transactionsQueryEC(params) {
  return request(`/api/transactions_ec`, {
    method: 'POST',
    body: {
      ...params,
    },
  });
}

// ec详情
export async function transactionDetailQueryEC(id) {
  return request(`/api/transactions_ec/${id}`, {
    method: 'GET',
  });
}

// 邮件中心导出
export async function mailExportTask(params) {
  return request(`/api/sale_mail/export_tasks`, {
    method: 'POST',
    body: {
      ...params,
    },
  });
}

// 订单中心
export async function queryOrder(params) {
  const { simpleQuery, batchQuery } = params;
  // const q = {
  //   filter: simpleQuery.filter,
  //   sort: simpleQuery.sort,
  //   page: simpleQuery.page,
  //   page_size: simpleQuery.page_size,
  // };
  return request(`/api/orders`, {
    method: 'POST',
    body: {
      ...batchQuery,
      ...simpleQuery,
    },
  });
}

export async function queryEcOrder(params) {
  const { simpleQuery, batchQuery } = params;
  return request(`/api/orders_ec`, {
    method: 'POST',
    body: {
      ...batchQuery,
      ...simpleQuery,
    },
  });
}

export async function orderExportTask(params) {
  return request(`/api/orders/export_tasks`, {
    method: 'POST',
    body: {
      ...params,
    },
  });
}

export async function batchOrderRefund(params) {
  return request(`/api/refund/multi-create`, {
    method: 'POST',
    body: {
      ...params,
    },
  });
}

// 运营管理 订单导出

export async function queryOrderExport(params) {
  const { simpleQuery, batchQuery } = params;
  return request(`/api/order_export`, {
    method: 'POST',
    body: {
      ...batchQuery,
      ...simpleQuery,
    },
  });
}

export async function OrderExport(params) {
  return request(`/api/order_export/export_tasks`, {
    method: 'POST',
    body: {
      ...params,
    },
  });
}

export async function queryExportList(params) {
  return request(`/api/order_export/export_tasks?${stringify(params)}`, {
    method: 'GET',
  });
}

export async function querySuccessList(params) {
  return request(`/api/xborder/order_success_rate?${stringify(params)}`, {
    method: 'GET',
  });
}

// xborder工单
export async function queryXBorderList(params) {
  const { simpleQuery, batchQuery } = params;
  return request(`/api/xb/dispute`, {
    method: 'POST',
    body: {
      ...batchQuery,
      ...simpleQuery,
    },
  });
}

export async function XborderExport(params) {
  return request(`/api/xb/dispute/export`, {
    method: 'POST',
    body: {
      ...params,
    },
  });
}

// 回复（新）
export async function disputeReplyMessage(data, id) {
  return request(`/api/xb/dispute/reply/${id}`, {
    method: 'POST',
    body: {
      ...data,
    },
  });
}

export async function saasReplyImage(params) {
  return request(`/api/saas_dispute/reply`, {
    method: 'POST',
    body: params,
  });
}

export async function uploadInfo(id, params) {
  return request(`/api/accounts/upload_attachment/${id}`, {
    method: 'POST',
    body: params,
  });
}

export async function QrCode(params) {
  return request(`/api/google_verify_code/qr_code`, {
    method: 'POST',
    body: params,
  });
}

// 上传证据
export async function uploadEvidence(params) {
  return request(`/api/paypal/attachment`, {
    method: 'POST',
    body: params,
  });
}

// 拒付主图上传
export async function uploadXborderChargeback(params) {
  return request(`/api/xb/attachment`, {
    method: 'POST',
    body: params,
  });
}

export async function exportEvidence(id, params) {
  return request(`/api/${id}`, {
    method: 'POST',
    body: {
      ...params,
    },
  });
}

export async function evidenceQuery(params) {
  return request(`/api/evidence_attachments`, {
    method: 'POST',
    body: {
      ...params,
    },
  });
}

function dealWithFilter(params) {
  let query1 = '?';
  Object.keys(params).forEach(key => {
    if (params[key] !== '' && params[key] !== undefined) query1 += `${key}=${params[key]}&`;
  });
  if (query1 !== '?') {
    return query1.substr(0, query1.lastIndexOf('&'));
  }
  return '';
}

function dealWithUrl(url, params) {
  let requestUrl = url;
  if (params && Object.keys(params).length) {
    requestUrl += dealWithFilter(params);
  }
  return requestUrl;
}

// 店铺搜索中关键词搜索列表
export async function fetchThirdLists(params) {
  const url = dealWithUrl('/api/bi_analyse/stores/third_domains', params);
  return request(url);
}

// SKU争议获取着陆页
export async function fetchSkuDisputeLandingPage(params) {
  return request(`/api/bi_analyse/sku_correspond/product_url?${stringify(params)}`, {
    method: 'GET',
  });
}

// 店铺争议异步导出
export async function asyncDownloadStoreDisputeJob(data) {
  return request('/api/dispute_analyse/store_dispute/export', {
    method: 'POST',
    body: {
      ...data,
    },
  });
}

// SKU争议导出
export async function skuDownload(data) {
  return request('/api/dispute_analyse/sku_dispute/export', {
    method: 'POST',
    body: {
      ...data,
    },
  });
}

// SKU分店铺争议导出
export async function skuStoreDownload(data) {
  return request('/api/dispute_analyse/sku_dispute/store_export', {
    method: 'POST',
    body: {
      ...data,
    },
  });
}

// 团队争议导出
export async function partDownload(data) {
  return request('/api/dispute_analyse/department_dispute/export', {
    method: 'POST',
    body: {
      ...data,
    },
  });
}

// 家族争议导出
export async function familyDownload(data) {
  return request('/api/dispute_analyse/paypal_dispute/export', {
    method: 'POST',
    body: {
      ...data,
    },
  });
}

// 拒付筛选值
export async function xborderRejection() {
  return request('/api/xborder/config', {
    method: 'GET',
  });
}

// 拒付列表
export async function xborderChargeback(data, meta) {
  return request(`/api/xb/chargeback?page=${meta.page}&per_page=${meta.page_size}`, {
    method: 'POST',
    body: {
      ...data,
    },
  });
}

// 导出拒付列表
export async function exportXborderChargeback(data) {
  return request('/api/xborder/chargeback/export_tasks', {
    method: 'POST',
    body: {
      ...data,
    },
  });
}

// 导出拒付列表
export async function orderInfo(data) {
  return request('/api/orders/order_info', {
    method: 'POST',
    body: {
      ...data,
    },
  });
}

// 面单批量上传提交数据
export async function batchCreateExpressFace(data) {
  return request('/api/orders/batch_create_express_face', {
    method: 'POST',
    body: {
      ...data,
    },
  });
}

// 发货证明批量上传提交数据
export async function uploadExpRecord(data) {
  return request('/api/orders/upload-exp-record', {
    method: 'POST',
    body: {
      ...data,
    },
  });
}

// 交易号查询订单物流单号
export async function trackNo(data) {
  return request('/api/lfd-order-center/order/track-no', {
    method: 'POST',
    body: {
      ...data,
    },
  });
}

// 每日报告列表
export async function getExportTask(data) {
  return request('/api/export-task', {
    method: 'POST',
    body: {
      ...data,
    },
  });
}

// 创建任务
export async function createTasks(data) {
  return request('/api/export-task/create', {
    method: 'POST',
    body: {
      ...data,
    },
  });
}

// 获取阿里云oss令牌
export async function getAlioss() {
  return request('/api/resource/alioss', {
    method: 'GET',
  });
}
// 交易记录上传 一次性报告
export async function getReport(data) {
  return request('/api/export-task/report', {
    method: 'POST',
    body: {
      ...data,
    },
  });
}
// 物流单详情（批量）
export async function getTrackInfo(data) {
  return request('/api/lfd-track-info', {
    method: 'POST',
    body: {
      ...data,
    },
  });
}

// 物流单快递轨迹记录（批量）
export async function getTrackRecord(data) {
  return request('/api/lfd-track-info/express/record', {
    method: 'POST',
    body: {
      ...data,
    },
  });
}

// 根据订单号物流单快递轨迹记录（批量）
export async function getTrackRecordByOrderId(id, order) {
  return request('/api/lfd-order-center/order/track-no-by-txnid', {
    method: 'POST',
    body: {
      id, // 订单号/交易号
      order, // 非空则传递的是订单号 否则为交易号
    },
  });
}

// 订单的虚拟物流单号及流水明细
export async function getVmExpress(params) {
  return request(`/api/lfd-track-info/order/vm-express/record?${stringify(params)}`, {
    method: 'GET',
  });
}

// 全部帐号Email和家族
export async function paypalAccount() {
  return request(`/api/paypal/account`, {
    method: 'GET',
  });
}

// 交易报告
export async function transactionReport(data) {
  return request(`/api/lfd-paypal-transaction/report`, {
    method: 'POST',
    body: {
      ...data,
    },
  });
}

// saas设置标签
export async function setSaasDisputeTag(id, data) {
  return request(`/api/saas/dispute/${id}/tag`, {
    method: 'POST',
    body: {
      ...data,
    },
  });
}

// saas获取标签
export async function getSaasDisputeTag(id) {
  return request(`/api/saas/dispute/${id}/tag`, {
    method: 'GET',
  });
}

// 争议分析 争议指派客服
export async function disputeDistribute(data) {
  return request(`/api/dispute/distribute`, {
    method: 'POST',
    body: {
      ...data,
    },
  });
}

// 订单概要
export async function simpleOrderCenter(data) {
  return request(`/api/lfd-order-center`, {
    method: 'POST',
    body: {
      ...data,
    },
  });
}

// 订单中心点击导出面单、发货证明、快照
export async function orderExportTrack(data) {
  return request(`/api/lfd-order-center/order/export_track`, {
    method: 'POST',
    body: {
      ...data,
    },
  });
}

// 更新同步记录
export async function getDownloadRecord(id) {
  return request(`/api/dispute/${id}/download-record`, {
    method: 'GET',
  });
}

// 邮件列表
export async function paypalMailAPI(param) {
  const formatParam = formatTableParams(param);
  return request(`/api/paypal-mail?${stringify(formatParam)}`, {
    method: 'GET',
  });
}

// 邮件详情
export async function paypalMailInfoAPI(id) {
  return request(`/api/paypal-mail/${id}`, {
    method: 'GET',
  });
}

// 设置邮件标签
export async function setPaypalMailTagAPI(id, tag) {
  return request(`/api/paypal-mail/${id}`, {
    method: 'PUT',
    body: {
      tag,
    },
  });
}

// 邮件标签
export async function getPaypalMailTagAPI() {
  return request('/api/paypal-mail/tag', {
    method: 'GET',
  });
}

// 保存邮件标签
export async function createPaypalMailTagAPI(tag) {
  return request(`/api/paypal-mail/tag/${tag}`, {
    method: 'POST',
  });
}

// 删除邮件标签
export async function deletePaypalMailTagAPI(tag) {
  return request(`/api/paypal-mail/tag/${tag}`, {
    method: 'DELETE',
  });
}

// 删除邮件
export async function deletePaypalMailAPI(id) {
  return request(`/api/paypal-mail/${id}`, {
    method: 'DELETE',
  });
}

// 邮件自动化规则 - 邮箱管理 - 删除
export async function deletePaypalMailAccountAPI(id) {
  return request(`/api/paypal-mail/account`, {
    method: 'DELETE',
    body: {
      id,
    },
  });
}

// 邮箱管理：列表
export async function paypalMailAccountAPI(param) {
  return request(`/api/paypal-mail/account?${stringify(param)}`, {
    method: 'GET',
  });
}

// 邮箱管理：开启关闭
export async function openPaypalMailAccountAPI(id) {
  return request(`/api/paypal-mail/account/${id}`, {
    method: 'PUT',
  });
}

// 邮件规则：新增编辑
export async function createPaypalMailRegxAPI(data, id) {
  if (id) {
    return request(`/api/paypal-mail-regx/${id}`, {
      method: 'POST',
      body: {
        ...data,
      },
    });
  }
  return request(`/api/paypal-mail-regx/0`, {
    method: 'POST',
    body: {
      ...data,
    },
  });
}

// 邮件规则：列表
export async function fetchPaypalMailRegxAPI(param) {
  return request(`/api/paypal-mail-regx?${stringify(param)}`, {
    method: 'GET',
  });
}

// 邮件规则：删除
export async function detelePaypalMailRegxAPI(id) {
  return request(`/api/paypal-mail-regx/${id}`, {
    method: 'DELETE',
  });
}

// 订单物流单号
export async function trackNoDetail(data) {
  return request(`/api/lfd-order-center/order/track-no-detail`, {
    method: 'POST',
    body: {
      ...data,
    },
  });
}

// 导出物流信息
export async function exportExpApi({ filter = {}, url, source }) {
  return request(`/api/orders/export-exp`, {
    method: 'POST',
    body: {
      filter,
      download_url: url,
      source,
    },
  });
}

// 导出物流信息
export async function exportOrderSnapshotApi(order_id) {
  return request(`/api/order/snapshot`, {
    method: 'POST',
    body: {
      filter: {
        order_id,
      },
    },
  });
}

// Xborder 关键词-删除
export async function deleteReplyKeywordApi(id) {
  return request(`/api/xborder/reply_keyword`, {
    method: 'DELETE',
    body: {
      id,
    },
  });
}

// XB 关键词 - 添加/编辑
export async function editReplyKeywordApi(param, id) {
  return request(id ? `/api/xborder/reply_keyword/${id}` : `/api/xborder/reply_keyword`, {
    method: 'POST',
    body: {
      ...param,
    },
  });
}

// 创建任务
export async function evidenceAttachmentsExport(data) {
  return request('/api/evidence_attachments/export', {
    method: 'POST',
    body: {
      ...data,
    },
  });
}

// 创建任务
export async function replyKeywordExport(data) {
  return request('/api/xb/keyword/export', {
    method: 'POST',
    body: {
      ...data,
    },
  });
}

// 交易记录商品明细
export async function transactionItemDetailApi(txnId) {
  return request(`/api/lfd-order-center/transactionItemDetail/${txnId}`, {
    method: 'GET',
  });
}

// 删除模版
export async function deleteDisputeReplyTemplateApi(id) {
  return request(`/api/dispute_reply_template/${id}`, {
    method: 'DELETE',
  });
}

// 获取操作列表
export async function fetchAutoRuleActionApi() {
  return request(`/api/auto-rule/action/`, {
    method: 'GET',
  });
}

// 删除操作
export async function deleteAutoRuleActionApi(action) {
  return request(`/api/auto-rule/action/${action}`, {
    method: 'DELETE',
  });
}

// 创建任务
export async function createAutoRule(data) {
  return request(`/api/auto-rule`, {
    method: 'POST',
    body: {
      ...data,
    },
  });
}

// 创建任务
export async function editAutoRule(data, id) {
  return request(`/api/auto-rule/${id}`, {
    method: 'POST',
    body: {
      ...data,
    },
  });
}

// 规则列表
export async function fetchAutoRuleApi(param) {
  const formatParam = formatTableParams(param);
  return request(`/api/auto-rule?${stringify(formatParam)}`, {
    method: 'GET',
  });
}

// 删除规则列表
export async function deleteAutoRuleApi(id) {
  return request(`/api/auto-rule/${id}`, {
    method: 'DELETE',
  });
}

// 获取图片
export async function getMessageImageApi(id, url) {
  return request(`/api/paypal/dispute/${id}/msg-image`, {
    method: 'POST',
    body: {
      url,
    },
  });
}

// 订单的虚拟物流单号及流水明细(批量)
export async function getVmExpressApi(orderId) {
  return request(`/api/lfd-track-info/order/vm-express/record`, {
    method: 'POST',
    body: {
      orderId,
    },
  });
}

// 订单的虚拟物流单号及流水明细(批量)
export async function changePayPalAccountsApi(id, body) {
  return request(`/api/accounts/${id}`, {
    method: 'POST',
    body,
  });
}

// 批量回复
export async function disputeMultiReply(body) {
  return request('/api/paypal/dispute/multi-reply', {
    method: 'POST',
    body,
  });
}

// 批量打标签
export async function disputeTagBatchSet(body) {
  return request('/api/disputes/tag/batch_set', {
    method: 'POST',
    body,
  });
}

// 订单概要
export async function getDisputeByTxId(param) {
  return request(`/api/dispute/${param}`, {
    method: 'GET',
  });
}

// 工单详情 V2
export async function getXBDisputeDetail(order_id) {
  return request(`/api/xb/dispute_detail/${order_id}`, {
    method: 'GET',
  });
}

// 详情
export async function getSaasDisputeDetail(body) {
  return request(`/api/saas_dispute/detail`, {
    method: 'POST',
    body,
  });
}

export async function auditOAStatus(id) {
  return request(`/api/audit/oa/${id}`, {
    method: 'PUT',
  });
}

export default {
  'transactionRecord.title': '交易记录',
  'transactionRecord.all': '全部',
  'transactionRecord.transaction_id': '交易号',
  'transactionRecord.invoice_id': '账单号',
  'transactionRecord.payer_email': '买家邮箱',
  'transactionRecord.dispute_id': '争议编号',
  'transactionRecord.reference_id': '关联订单',
  'transactionRecord.double_click': '双击可进入批量查询',
  'transactionRecord.pp_account': 'PP账号',
  'transactionRecord.pp_account_tip': '点击可多选',
  'transactionRecord.creation_date': '创建日期',
  'transactionRecord.date_start': '开始日期',
  'transactionRecord.date_end': '结束日期',
  'transactionRecord.inquire': '查询',
  'transactionRecord.ec_inquire': 'EC 查询',
  'transactionRecord.reset': '重置',
  'transactionRecord.export': '导出查询结果',
  'transactionRecord.refund': '批量退款',
  'transactionRecord.orig_transaction_id': '原始交易号',
  'transactionRecord.refunded': '已退款',
  'transactionRecord.disputed': '有争议',
  'transactionRecord.date': '日期',
  'transactionRecord.type': '类型',
  'transactionRecord.buyer_name': '买家姓名',
  'transactionRecord.status': '状态',
  'transactionRecord.currency': '币种',
  'transactionRecord.update_doc_no': '是否更新单号',
  'transactionRecord.total': '总额',
  'transactionRecord.handling_fee': '手续费',
  'transactionRecord.net_amount': '净额',
  'transactionRecord.operation': '操作',
  'transactionRecord.details': '详情',
  'transactionRecord.details_refund': '退款',
  'transactionRecord.batch_query': '批量查询',
  'transactionRecord.cancel': '取消',
  'transactionRecord.confirm': '确定',
  'transactionRecord.modal_tip': '每行一个 {n}',
  'transactionRecord.input_null': '请输入查询项',
  'transactionRecord.select_null': '请选择查询项',
  'transactionRecord.input_less': '请至少选择一个查询项',
  'transactionRecord.no_inquire': '暂未进行查询',
  'transactionRecord.export_success': '已生成导出任务',
  'transactionRecord.refund_success': '批量退款成功',
  'transactionRecord.refund_fail': '批量退款失败',

  'transactionRecord.bulk_refund_title': '批量退款-导入文件',
  'transactionRecord.bulk_refund_tips1': '1.对已存在的数据将会进行更新，但还是要谨慎操作',
  'transactionRecord.bulk_refund_tips2': '2.单次上传仅支持',
  'transactionRecord.bulk_refund_tips2.1': '条数据，超过的建议分批次操作',
  'transactionRecord.bulk_refund_tips3': '3.请下载模板，根据模板提示填入数据：',
  'transactionRecord.download_template': '下载模板',
  'transactionRecord.please_upload': '请上传：',
  'transactionRecord.please_upload_tip1': '点击选择或拖拽文件到线框中上传',
  'transactionRecord.please_upload_tip2': '仅支持小于10M的XSLX、XLS格式的excel文件',

  'transactionRecord.file_less': '请至少选择一个文件',
  'transactionRecord.file_large': '不能超过10M,请移除并重新选择文件',
  'transactionRecord.file_long': '表格行数超过2000行，请重新选择',
  'transactionRecord.file_parameters_wrong': '您上传的文件参数有误，请重新选择',
  'transactionRecord.file_type_incorrect': '文件类型不正确！',

  'transactionRecord.refund_amount': '退款金额',
  'transactionRecord.refund_reason': '退款原因',
  'transactionRecord.refund_remarks': '退款备注',
  'transactionRecord.refund_sku': '退款SKU',
  'transactionRecord.got_it': '知道了',

  'transactionRecord.refund_success_tip': '推送退款队列成功，请稍后至用户审计查看结果',
  'transactionRecord.fail': '失败',

  'transactionRecord.key': '字段',
  'transactionRecord.selected': '所选值',
  'transactionRecord.amount_more': '不能超过退款金额',
  'transactionRecord.amount_less': '请输入大于等于0的数字',
  'transactionRecord.custom': '自定义',
  'transactionRecord.buyer_information': '买家信息',
  'transactionRecord.order_amount': '订单金额',
  'transactionRecord.refunded_amount': '已退款金额',
  'transactionRecord.transaction_refund': '交易退款',
  'transactionRecord.refund_amount_tip': '请输入退款金额',
  'transactionRecord.paypal_refund_remarks': 'paypal退款备注',
  'transactionRecord.paypal_refund_remarks_tip': '此备注信息可被客户看到',
  'transactionRecord.refund_reason_select_tip': '请选择退款原因',
  'transactionRecord.please_select': '请选择',
  'transactionRecord.refund_remarks_tip': '售后系统退款备注, 仅供售后系统使用',
  'transactionRecord.refund_label': '退款标签',
  'transactionRecord.refund_label_tip': '请选择退款标签',
  'transactionRecord.please_select_label': '请选择标签',
  'transactionRecord.label_name': '标签名称',
  'transactionRecord.label_name_tip': '请输入自定义标签名称',
  'transactionRecord.select_sku': '请选择SKU',

  'transactionRecord.showTotal': '共 {total} 条数据',

  'transactionRecord.edit_logistics_info': '编辑物流信息',
  'transactionRecord.add_logistics_info': '添加物流信息',
  'transactionRecord.transaction_note': '备注',
  'transactionRecord.logistics_status': '物流状态',
  'transactionRecord.logistics_status_no_null': '物流状态不可为空',
  'transactionRecord.select_logistics_status': '请选择物流状态',
  'transactionRecord.tracking_number': '运单编号',
  'transactionRecord.tracking_number_no_null': '运单编号不可为空',
  'transactionRecord.tracking_number_please_enter': '请输入运单编号',
  'transactionRecord.carrier': '承运商',
  'transactionRecord.carrier_tip': '请选择承运商',
  'transactionRecord.other': '自定义承运商',
  'transactionRecord.enter_other': '请输入自定义承运商',
  'transactionRecord.logistic_order_number': '物流单号',
  'transactionRecord.confirm_clone': '确认克隆吗',
  'transactionRecord.clone': '克隆',
  'transactionRecord.product_name': '商品名称',
  'transactionRecord.product_quantity': '商品数量',
  'transactionRecord.price': '价格',
  'transactionRecord.subtotal': '小计',
  'transactionRecord.edit': '编辑',
  'transactionRecord.confirm_delete': '确认删除吗',
  'transactionRecord.delete': '删除',
  'transactionRecord.dispute_id_number': '事件编号',
  'transactionRecord.created_at': '创建时间',
  'transactionRecord.updated_at': '更新时间',
  'transactionRecord.reason': '原因',
  'transactionRecord.dispute_amount': '争议金额',
  'transactionRecord.adjudication_result': '裁决结果(胜诉方)',
  'transactionRecord.trading_time': '交易时间',
  'transactionRecord.payment_status': '付款状态',
  'transactionRecord.fee': '手续费',
  'transactionRecord.nothing': '暂无',
  'transactionRecord.add_logistics_success': '添加物流信息成功',
  'transactionRecord.update_logistics_success': '更新物流信息成功',
  'transactionRecord.delete_logistics_success': '删除物流信息成功',
  'transactionRecord.delete_logistics_failed': '删除失败',
  'transactionRecord.clone_logistics_success': '克隆成功',
  'transactionRecord.clone_logistics_wrong': '出错了',
  'transactionRecord.information_missing': '信息缺失',
  'transactionRecord.transaction_details': '交易详情',
  'transactionRecord.consignee_name': '收货人姓名',
  'transactionRecord.shipping_address': '收货地址',
  'transactionRecord.unverified': '未验证',
  'transactionRecord.verified': '已验证',
  'transactionRecord.seller_protection': '卖家保障',
  'transactionRecord.time': '时间',
  'transactionRecord.payer_name': '付款人姓名',
  'transactionRecord.payer_email_address': '付款人邮箱',
  'transactionRecord.custom_field': '自定义字段',
  'transactionRecord.shipping_information': '运送资料',
  'transactionRecord.order_details': '订单详细信息',
  'transactionRecord.payment_details': '付款信息',
  'transactionRecord.purchase_total': '购物总额',
  'transactionRecord.sales_tax': '梯度优惠',
  'transactionRecord.shipping_amount': '运费金额',
  'transactionRecord.total_amount': '总额',
  'transactionRecord.payPal_fee': 'Paypal费用',
  'transactionRecord.previous': '上一条',
  'transactionRecord.next': '下一条',

  'transactionRecord.yes': '是',
  'transactionRecord.no': '否',
};

import { query, add } from '@/services/api';
import { generateUuid } from '@/utils/utils';

export default {
  namespace: 'disputes',
  state: {
    store: {
      data: [],
      totalData: [],
      totalCount: 0,
      testTotal: [],
      haveTest: false,
    },
    ppFamily: {
      data: [],
      totalData: [],
      total: 0,
    },
    depart: {
      data: [],
      totalData: [],
      totalCount: 0,
      testTotal: [],
      haveTest: false,
    },
    vSku: {
      data: [],
      total: 0,
      totalData: [],
    },
    tSku: {
      data: [],
      total: 0,
      totalData: [],
    },
    sku: {
      data: [],
      total: 0,
      totalData: [],
      testData: [],
    },
    list: {
      parts: [],
      peoples: [],
    },
  },

  effects: {
    *getStoreDispute({ payload }, { call, put }) {
      const response = yield call(add, 'dispute_analyse/store_dispute', payload);
      yield put({
        type: 'saveStoreDispute',
        payload: response,
      });
      return response;
    },
    *getPPFamilyDispute({ payload }, { call, put }) {
      const response = yield call(add, 'dispute_analyse/paypal_dispute', payload);
      yield put({
        type: 'savePPFamilyDispute',
        payload: response,
      });
      return response;
    },
    *fetchSkuDispute({ payload, saveTotal = true }, { call, put }) {
      const response = yield call(add, 'dispute_analyse/sku_dispute', payload);
      yield put({
        type: 'saveSkuDispute',
        payload: response,
        saveTotal,
      });
      return response;
    },
    // 分店铺SKU争议
    *getSkuDisputeByStore({ payload, saveTotal = true }, { call, put }) {
      const response = yield call(add, 'dispute_analyse/sku_dispute/store', payload);
      yield put({
        type: 'saveSkuDisputeByStore',
        payload: response,
        saveTotal,
      });
      return response;
    },
    // 部门争议
    *getPartDispute({ payload }, { call, put }) {
      const response = yield call(add, 'dispute_analyse/department_dispute', payload);
      yield put({
        type: 'savePartDispute',
        payload: response,
      });
      return response;
    },
    *getParts(_, { call, put }) {
      const response = yield call(query, 'bi_analyse/store_list');
      yield put({
        type: 'savePart',
        payload: response,
      });
      return response;
    },
    *getStores(_, { call, put }) {
      const response = yield call(query, 'bi_analyse/store_list');
      yield put({
        type: 'savePart',
        payload: response,
      });
      return response;
    },
    *getVskuDispute({ payload }, { call, put }) {
      const response = yield call(add, 'dispute_analyse/sku_dispute', payload);

      yield put({
        type: 'saveVskuDispute',
        payload: response,
      });
      return response;
    },
  },

  reducers: {
    saveStoreDispute(state, action) {
      const { data = [], total = [], meta = {} } = action.payload || {};
      const testTotal = total.find(i => i.part_type === '外部');
      // const innerTotal = total.find(i => i.part_type === '内部');
      // const totalDataSource = [];
      // if (innerTotal && !['{}', '[]'].includes(JSON.stringify(innerTotal))) {
      //   innerTotal.id = 1;
      //   totalDataSource.push(innerTotal);
      // }
      let tempTestTotal = [];
      let tempHaveTest = false;
      if (testTotal) {
        tempTestTotal = [testTotal];
        tempHaveTest = true;
      }
      return {
        ...state,
        store: {
          data: data?.map(i => ({ ...i, uuid: generateUuid() })),
          totalData: total,
          totalCount: meta?.total || 0,
          testTotal: tempTestTotal,
          haveTest: tempHaveTest,
        },
      };
    },
    savePPFamilyDispute(state, action) {
      const { data = [], total = [], meta } = action.payload || [];
      // const totalDataSource = [];
      // if (total && !['{}', '[]'].includes(JSON.stringify(total))) {
      //   total.id = 1;
      //   totalDataSource.push(total);
      // }

      return {
        ...state,
        ppFamily: {
          data: data?.map(i => ({ ...i, uuid: generateUuid() })) || [],
          totalData: total || [],
          total: meta?.total || 0,
        },
      };
    },
    savePartDispute(state, action) {
      const { data = [], total = [], meta = {} } = action.payload || {};
      // const testTotal = total.find(i => i.part_type === '外部');
      // const innerTotal = total.find(i => i.part_type === '内部');
      // const totalDataSource = [];
      // if (innerTotal && !['{}', '[]'].includes(JSON.stringify(innerTotal))) {
      //   innerTotal.id = 1;
      //   totalDataSource.push(innerTotal);
      // }
      // let tempTestTotal = [];
      // let tempHaveTest = false;
      // if (testTotal) {
      //   tempTestTotal = [testTotal];
      //   tempHaveTest = true;
      // }
      return {
        ...state,
        depart: {
          data: data?.map(i => ({ ...i, uuid: generateUuid() })),
          totalData: total,
          totalCount: meta?.total || 0,
          // testTotal: tempTestTotal,
          // haveTest: tempHaveTest,
        },
      };
    },
    saveSkuDisputeByStore(state, action) {
      const { data = [], meta = {}, total } = action.payload;
      const { saveTotal = true } = action.saveTotal;
      const totalData = total;

      if (saveTotal) {
        return {
          ...state,
          sku: {
            data: data.map(i => ({ ...i, uuid: generateUuid() })),
            total: meta?.total || 0,
            totalData: [totalData],
          },
        };
      }
      return {
        ...state,
        sku: {
          data: data.map(i => ({ ...i, uuid: generateUuid() })),
          total: meta?.total || 0,
          // totalData:[totalData],
        },
      };
    },
    saveSkuDispute(state, action) {
      const { data = [], meta = {}, total: totalData = {} } = action.payload;
      const { saveTotal = true } = action.saveTotal;

      const totalDataSource = [];
      if (totalData && !['{}', '[]'].includes(JSON.stringify(totalData))) {
        totalData.id = 2;
        totalDataSource.push(totalData);
      }
      if (saveTotal) {
        return {
          ...state,
          sku: {
            data: data.map(i => ({ ...i, uuid: generateUuid() })),
            total: meta?.total || 0,
            totalData: totalDataSource || [],
          },
        };
      }
      return {
        ...state,
        sku: {
          data: data.map(i => ({ ...i, uuid: generateUuid() })),
          total: meta?.total || 0,
          totalData: state?.sku?.totalData || [],
        },
      };
    },
    savePart(state, action) {
      return {
        ...state,
        list: {
          parts: action.payload.parts,
          peoples: action.payload.peoples,
        },
      };
    },
    saveVskuDispute(state, action) {
      const { data = [], meta = {}, total: totalData = {} } = action.payload;
      const totalDataSource = [];
      if (totalData && !['{}', '[]'].includes(JSON.stringify(totalData))) {
        totalData.id = 1;
        totalDataSource.push(totalData);
      }
      return {
        ...state,
        vSku: {
          data: data?.map(i => ({ ...i, uuid: generateUuid() })),
          total: meta?.total || 0,
          totalData: totalDataSource || [],
        },
      };
    },
  },
};

import { queryNotices, clearNotices, markNoticeRead, query, createTasks } from '@/services/api';
import { getCacheWithExpiryTime, setCacheWithExpiryTime } from '@/utils/utils';
import logo from '@/assets/logo.svg';

export default {
  namespace: 'global',

  state: {
    collapsed: false,
    notices: [],
    settings: {
      title: '售后系统',
      logo,
    },
    configLoading: true,
    account: {},
    status: {},
    type: {},
    currency: {},
    carriers: {},
    refundAddress: {},
    user: {},
    protection_eligibility: {},
    transaction_track_status: {},
    disputeOption: {},
    powerBrowser: {},
    roles: {},
    tags: {},
    mailTemplete: {},
    variable: {},
    permission_param_info: {},
    XborderReplyTemplate: [],
    XborderReplyTemplate_tag: {},
    xborderReplyKeyword: [],
    xborderReplyKeywordJson: {},
    xbChargeback: {},
    Audit_action: [],
    Audit_handle: [],
    PaypalRegulation: {},
    disputeReplyAttachmentType: [],
    language: [],
    AutoRule: {
      channelParam: {},
      autoRuleItemOp: [],
      disputeLifeCycleStage: [],
      reason: [],
      status: [],
      disputeChannel: [],
      handleTarget: [],
      saasDisputeTitle: [],
      xborderDisputeTitle: [],
    },
    config: {},
    ppqueryOrderCenter: [],
    originRoute: {},
  },

  effects: {
    *fetchNotices(_, { call, put, select }) {
      const res = yield call(queryNotices);
      yield put({
        type: 'saveNotices',
        payload: res.data,
      });
      const unreadCount = yield select(
        state => state.global.notices.filter(item => !item.read).length
      );
      yield put({
        type: 'user/changeNotifyCount',
        payload: {
          totalCount: res.data.length,
          unreadCount,
        },
      });
    },
    *clearNotices({ payload }, { call, put, select }) {
      yield call(clearNotices);
      yield put({
        type: 'saveClearedNotices',
        payload,
      });
      const count = yield select(state => state.global.notices.length);
      const unreadCount = yield select(
        state => state.global.notices.filter(item => !item.read).length
      );
      yield put({
        type: 'user/changeNotifyCount',
        payload: {
          totalCount: count,
          unreadCount,
        },
      });
    },
    *changeNoticeReadState({ payload }, { call, put, select }) {
      yield call(markNoticeRead, payload);
      const notices = yield select(state =>
        state.global.notices.map(item => {
          const notice = { ...item };
          if (notice.id === payload) {
            notice.read = true;
          }
          return notice;
        })
      );
      yield put({
        type: 'saveNotices',
        payload: notices,
      });
      yield put({
        type: 'user/changeNotifyCount',
        payload: {
          totalCount: notices.length,
          unreadCount: notices.filter(item => !item.read).length,
        },
      });
    },
    *querySettings(_, { call, put }) {
      const res = yield call(query, 'settings');
      yield put({
        type: 'saveSettings',
        payload: res,
      });
    },

    *fetchConfig({ payload, forceRefresh = false }, { call, put }) {
      let response;

      if (!forceRefresh) {
        // 尝试从缓存中读取数据
        const cachedConfig = getCacheWithExpiryTime('config');
        if (cachedConfig) {
          // 如果缓存中有数据，直接使用缓存的数据
          response = JSON.parse(cachedConfig);
        }
      }

      if (!response) {
        // 如果缓存中没有数据或强制刷新，从请求中获取
        response = yield call(query, 'config', payload);
        // 将获取到的数据放到缓存中
        setCacheWithExpiryTime('config', JSON.stringify(response));
      }

      // 将数据保存到 state 中
      yield put({
        type: 'saveConfig',
        payload: response,
      });
    },

    // 创建导出任务
    *createTasks({ payload }, { call }) {
      const response = yield call(createTasks, payload);
      return response;
    },

    // 填充原始路由数据
    *fillRoute({ payload }, { put }) {
      yield put({
        type: 'saveRoute',
        payload,
      });
    },
  },

  reducers: {
    changeLayoutCollapsed(state, { payload }) {
      return {
        ...state,
        collapsed: payload,
      };
    },
    saveNotices(state, { payload }) {
      return {
        ...state,
        notices: payload,
      };
    },
    saveClearedNotices(state, { payload }) {
      return {
        ...state,
        notices: state.notices.filter(item => item.type !== payload),
      };
    },
    saveSettings(state, { payload }) {
      return {
        ...state,
        settings: payload,
      };
    },
    saveConfig(state, action) {
      const xborderReplyKeywordJson = {};
      if (action?.payload?.XborderReplyKeyword?.type) {
        action.payload.XborderReplyKeyword.type.forEach(item => {
          xborderReplyKeywordJson[item.key] = item.val;
        });
      }
      const Audit_action = action.payload.Audit.action || [];
      // if (action?.payload?.Audit?.action) {
      //   action.payload.Audit.action.forEach(item => {
      //     Audit_action[item.key] = item.val;
      //   });
      // }
      const Audit_handle = action?.payload?.Audit?.handle || [];
      const Audit_status = action?.payload?.Audit?.status || [];
      // if (action?.payload?.Audit?.handle) {
      //   action.payload.Audit.handle.forEach(item => {
      //     Audit_handle[item.key] = item.val;
      //   });

      // }
      return {
        ...state,
        config: action.payload,
        account: action.payload.account,
        type: action.payload.type,
        status: action.payload.status,
        currency: action.payload.currency,
        countries: action.payload.countries,
        roles: action.payload.roles,
        carriers: action.payload.carriers,
        refundAddress: action.payload.refund_address,
        user: action.payload.user,
        protection_eligibility: action.payload.protection_eligibility,
        transaction_track_status: action.payload.transaction_track_status,
        disputeOption: action.payload.paypal_dispute_option,
        powerBrowser: action.payload.power_browser,
        tags: action.payload.tags,
        XborderReplyTemplate: action.payload.XborderReplyTemplate.type,
        XborderReplyTemplate_tag: action.payload.XborderReplyTemplate_tag,
        permission_param_info: action.payload.permission_param_info,
        mailTemplete: action.payload.mail_template,
        variable: action.payload.mail_template_variable,
        Audit_action,
        Audit_handle,
        Audit_status,
        xborderReplyKeyword: action.payload.XborderReplyKeyword.type,
        xborderReplyKeywordJson,
        xbChargeback: action.payload.XbChargeback,
        PaypalRegulation: action.payload.PaypalRegulation,
        disputeReplyAttachmentType: action?.payload?.DisputeReplyAttachment?.type || [],
        language: action?.payload?.AppServicesLanguage?.language || [],
        AutoRule: {
          channelParam: action?.payload?.AutoRuleDetail?.channel_param || {},
          autoRuleItemOp: action?.payload?.AutoRuleItem?.op || [],

          disputeLifeCycleStage: action?.payload?.Dispute?.dispute_life_cycle_stage || [],
          reason: action?.payload?.Dispute?.reason || [],
          status: action?.payload?.Dispute?.status || [],

          disputeChannel: action?.payload?.DisputeDetail?.dispute_channel || [],

          handleTarget: action?.payload?.AutoRule?.handle_target || [],

          saasDisputeTitle: action?.payload?.SaasSaasDispute?.title || [],
          xborderDisputeTitle: action?.payload?.XborderDispute?.title || [],
        },
        ReplayRule: action.payload.ReplayRule,
        configLoading: false,
        ppqueryOrderCenter: action?.payload?.lfd_ppquery?.order_center || [],
      };
    },
    saveRoute(state, action) {
      return {
        ...state,
        originRoute: action.payload,
      };
    },
  },

  subscriptions: {
    setup({ history }) {
      // Subscribe history(url) change, trigger `load` action if pathname is `/`
      return history.listen(({ pathname, search }) => {
        if (typeof window.ga !== 'undefined') {
          window.ga('send', 'pageview', pathname + search);
        }
      });
    },
  },
};

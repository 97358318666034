import * as Sentry from '@sentry/browser';

export const initSentry = () => {
  if (process.env.NODE_ENV === 'production') {
    Sentry.init({
      dsn: 'https://761824ccd4b160f3380018e568d84c0e@o467009.ingest.us.sentry.io/4506674981699584',
      release: VERSION,
      tracesSampleRate: 0.001,
      ignoreErrors: ['ResizeObserver loop limit exceeded'],
    });
  }
};

// Available levels are "fatal", "error", "warning", "log", "info", and "debug".
export const uploadToOssFail = ({
  name = 'unset',
  size = 'unset',
  url = 'unset',
  message = 'unset',
}) => {
  Sentry.withScope(scope => {
    scope.setLevel('log');
    scope.setTag('file.name', name);
    scope.setTag('file.size', size);
    scope.setTag('file.url', url);
    scope.setTransactionName('uploadToOssFail');
    Sentry.captureException(new Error(message));
  });
};

export default {
  'transactionRecord.title': 'Transaction Record',
  'transactionRecord.all': 'All',
  'transactionRecord.transaction_id': 'Transaction ID',
  'transactionRecord.invoice_id': 'Invoice ID',
  'transactionRecord.payer_email': 'Buyer Email',
  'transactionRecord.dispute_id': 'Dispute ID',
  'transactionRecord.reference_id': 'Associated order',
  'transactionRecord.double_click': 'Double click to enter batch query',
  'transactionRecord.pp_account': 'PP account',
  'transactionRecord.pp_account_tip': 'Click to select multiple',
  'transactionRecord.creation_date': 'Creation date',
  'transactionRecord.date_start': 'Start date',
  'transactionRecord.date_end': 'End date',
  'transactionRecord.inquire': 'Inquire',
  'transactionRecord.ec_inquire': 'EC Inquire',
  'transactionRecord.reset': 'Reset',
  'transactionRecord.export': 'Export query results',
  'transactionRecord.refund': 'Batch refund',
  'transactionRecord.orig_transaction_id': 'Orig Transaction ID',
  'transactionRecord.refunded': 'Refunded',
  'transactionRecord.disputed': 'Disputed',
  'transactionRecord.date': 'Date',
  'transactionRecord.type': 'Type',
  'transactionRecord.buyer_name': 'Buyer name',
  'transactionRecord.status': 'Status',
  'transactionRecord.currency': 'Currency',
  'transactionRecord.update_doc_no': 'Update number',
  'transactionRecord.total': 'Total',
  'transactionRecord.handling_fee': 'Handling fee',
  'transactionRecord.net_amount': 'Net amount',
  'transactionRecord.operation': 'Operation',
  'transactionRecord.details': 'Details',
  'transactionRecord.details_refund': 'Refund',
  'transactionRecord.batch_query': 'Batch query',
  'transactionRecord.cancel': 'Cancel',
  'transactionRecord.confirm': 'Confirm',
  'transactionRecord.modal_tip': 'One {n} per line',
  'transactionRecord.input_null': 'Please enter the query item',
  'transactionRecord.select_null': 'Please select query item',
  'transactionRecord.input_less': 'Please select at least one query item',
  'transactionRecord.no_inquire': 'No inquiry has been made yet',
  'transactionRecord.export_success': 'Export task has been generated',
  'transactionRecord.refund_success':
    'Successfully push the batch refund queue, please check the result of user audit later',
  'transactionRecord.refund_fail': 'Failed',

  'transactionRecord.bulk_refund_title': 'Bulk Refund-Import File',
  'transactionRecord.bulk_refund_tips1':
    '1. The existing data will be updated, but you still need to be cautious.',
  'transactionRecord.bulk_refund_tips2': '2. Single upload only supports ',
  'transactionRecord.bulk_refund_tips2.1':
    ' pieces of data. It is recommended to operate in batches if it exceeds',
  'transactionRecord.bulk_refund_tips3':
    '3. Please download the template and fill in the data according to the template prompt:',
  'transactionRecord.download_template': 'Download template',
  'transactionRecord.please_upload': 'Please upload:',
  'transactionRecord.please_upload_tip1':
    'Click to select or drag and drop files to the wireframe to upload',
  'transactionRecord.please_upload_tip2':
    'Only supports excel files in XSLX and XLS format less than 10M',

  'transactionRecord.file_less': 'Please select at least one file',
  'transactionRecord.file_large': 'Cannot exceed 10M, please remove and reselect the file',
  'transactionRecord.file_long':
    'The number of rows in the table exceeds 2000, please select again',
  'transactionRecord.file_parameters_wrong':
    'The parameters of the file you uploaded are wrong, please select again',
  'transactionRecord.file_type_incorrect': 'The file type is incorrect!',

  'transactionRecord.refund_amount': 'Refund amount',
  'transactionRecord.refund_reason': 'Refund reason',
  'transactionRecord.refund_remarks': 'Refund remarks',
  'transactionRecord.refund_sku': 'Refund SKU',
  'transactionRecord.got_it': 'got it',

  'transactionRecord.refund_success_tip':
    'Push the refund queue successfully, please check the result of user audit later',
  'transactionRecord.fail': 'Fail',

  'transactionRecord.key': 'Key',
  'transactionRecord.selected': 'Selected value',
  'transactionRecord.amount_more': 'Cannot exceed the refund amount',
  'transactionRecord.amount_less': 'Please enter a number greater than or equal to 0',
  'transactionRecord.custom': 'Custom',
  'transactionRecord.buyer_information': 'Buyer information',
  'transactionRecord.order_amount': 'Order amount',
  'transactionRecord.refunded_amount': 'Refunded amount',
  'transactionRecord.transaction_refund': 'Transaction refund',
  'transactionRecord.refund_amount_tip': 'Please enter the refund amount',
  'transactionRecord.paypal_refund_remarks': 'Refund remarks',
  'transactionRecord.paypal_refund_remarks_tip':
    'This remark information can be seen by the customer',
  'transactionRecord.refund_reason_select_tip': 'Please select the reason for refund',
  'transactionRecord.please_select': 'Please select',
  'transactionRecord.refund_remarks_tip':
    'After sales system refund remarks, only for after-sales system use',
  'transactionRecord.refund_label': 'Refund label',
  'transactionRecord.refund_label_tip': 'Please select the refund label',
  'transactionRecord.please_select_label': 'Please select a label',
  'transactionRecord.label_name': 'Label name',
  'transactionRecord.label_name_tip': 'Please enter a custom label name',
  'transactionRecord.select_sku': 'Please select SKU',

  'transactionRecord.showTotal': 'Total {total}',

  'transactionRecord.edit_logistics_info': 'Edit logistics information',
  'transactionRecord.add_logistics_info': 'Add logistics information',
  'transactionRecord.transaction_note': 'Remarks',
  'transactionRecord.logistics_status': 'Logistics status',
  'transactionRecord.logistics_status_no_null': 'Logistics status cannot be empty',
  'transactionRecord.select_logistics_status': 'Please select logistics status',
  'transactionRecord.tracking_number': 'Tracking number',
  'transactionRecord.tracking_number_no_null': 'Tracking number cannot be empty',
  'transactionRecord.tracking_number_please_enter': 'Please enter the waybill number',
  'transactionRecord.carrier': 'Carrier',
  'transactionRecord.carrier_tip': 'Please select a carrier',
  'transactionRecord.other': 'OTHER',
  'transactionRecord.enter_other': 'Please enter a custom carrier',
  'transactionRecord.logistic_order_number': 'Logistic order number',
  'transactionRecord.confirm_clone': 'Confirm clone',
  'transactionRecord.clone': 'Clone',
  'transactionRecord.product_name': 'Product name',
  'transactionRecord.product_quantity': 'Product quantity',
  'transactionRecord.price': 'Price',
  'transactionRecord.subtotal': 'Subtotal',
  'transactionRecord.edit': 'Edit',
  'transactionRecord.confirm_delete': 'Confirm delete',
  'transactionRecord.delete': 'Delete',
  'transactionRecord.dispute_id_number': 'Dispute id',
  'transactionRecord.created_at': 'Creation time',
  'transactionRecord.updated_at': 'Update time',
  'transactionRecord.reason': 'Reason',
  'transactionRecord.dispute_amount': 'Dispute amount',
  'transactionRecord.adjudication_result': 'Adjudication result',
  'transactionRecord.trading_time': 'Trading time',
  'transactionRecord.payment_status': 'Payment status',
  'transactionRecord.fee': 'Handling fee',
  'transactionRecord.nothing': 'Nothing',
  'transactionRecord.add_logistics_success': 'Add logistics information successfully',
  'transactionRecord.update_logistics_success': 'Update logistics information successfully',
  'transactionRecord.delete_logistics_success': 'Deleting logistics information successfully',
  'transactionRecord.delete_logistics_failed': 'Deletion failed',
  'transactionRecord.clone_logistics_success': 'Clone successful',
  'transactionRecord.clone_logistics_wrong': 'Something went wrong',
  'transactionRecord.information_missing': 'Information missing',
  'transactionRecord.transaction_details': 'Transaction Details',
  'transactionRecord.consignee_name': 'Consignee name',
  'transactionRecord.shipping_address': 'Shipping address',
  'transactionRecord.unverified': 'Unverified',
  'transactionRecord.verified': 'Verified',
  'transactionRecord.seller_protection': 'Seller protection',
  'transactionRecord.time': 'Time',
  'transactionRecord.payer_name': 'Payer name',
  'transactionRecord.payer_email_address': 'Payer’s email address',
  'transactionRecord.custom_field': 'Custom field',
  'transactionRecord.shipping_information': 'Shipping information',
  'transactionRecord.order_details': 'Order details',
  'transactionRecord.payment_details': 'Payment details',
  'transactionRecord.purchase_total': 'Purchase total',
  'transactionRecord.sales_tax': 'Sales tax',
  'transactionRecord.shipping_amount': 'Shipping amount',
  'transactionRecord.total_amount': 'Total amount',
  'transactionRecord.payPal_fee': 'PayPal fee',
  'transactionRecord.previous': 'Previous',
  'transactionRecord.next': 'Next',

  'transactionRecord.yes': 'Yes',
  'transactionRecord.no': 'No',
};

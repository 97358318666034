import { routerRedux } from 'dva/router';
import { stringify } from 'qs';
import { login, getFakeCaptcha } from '@/services/api';
import { queryCurrent } from '@/services/user';
import { setAuthorityByToken, setAuthorityByApi } from '@/utils/authority';
import { getPageQuery } from '@/utils/utils';
import { reloadAuthorized } from '@/utils/Authorized';

export default {
  namespace: 'login',

  state: {
    status: undefined,
  },

  effects: {
    *login({ payload }, { call, put }) {
      const response = yield call(login, payload);
      if (!response) {
        return;
      }
      yield put({
        type: 'changeLoginStatus',
        payload: response,
      });
      const res = yield call(queryCurrent);
      setAuthorityByApi(res.permission);
      reloadAuthorized();

      // 每次登录成功，强制刷新缓存
      yield put({
        type: 'user/fetchCurrent',
        payload: res,
        forceRefresh: true,
      });
      yield put({
        type: 'global/fetchConfig',
        forceRefresh: true,
      });

      const urlParams = new URL(window.location.href);
      const params = getPageQuery();
      let { redirect } = params;
      if (redirect) {
        const redirectUrlParams = new URL(redirect);
        if (redirectUrlParams.origin === urlParams.origin) {
          redirect = redirect.substr(urlParams.origin.length);
          if (redirect.match(/^\/.*#/)) {
            redirect = redirect.substr(redirect.indexOf('#') + 1);
          }
        } else {
          window.location.href = redirect;
          return;
        }
      }
      // yield put(routerRedux.replace(redirect || '/'));
      // if(res.role[0]==='business-support' || res.role[0]==='data-analyst' || res.role[0]==='documentary'){
      //   yield put(routerRedux.replace(redirect || '/merchant/transaction'));
      // }else if(res.role[0]==='customer-quality-control'){
      //   yield put(routerRedux.replace(redirect || '/merchant/emailCenter'));
      // }else{
      //   yield put(routerRedux.replace(redirect || '/'));
      // }
      // console.log(typeof(res.role.indexOf('customer-service-supervisor')) )
      if (res.permission.includes('disputes_reply')) {
        yield put(routerRedux.replace(redirect || '/'));
      } else if (res.permission.includes('manage_transactions')) {
        yield put(routerRedux.replace(redirect || '/merchant/transaction'));
      } else if (res.permission.includes('manage_orders')) {
        yield put(routerRedux.replace(redirect || '/merchant/orderCenter'));
      } else if (res.permission.includes('operate_manage_order_export')) {
        yield put(routerRedux.replace(redirect || '/operation/orderExport'));
      } else {
        yield put(routerRedux.replace(redirect || '/person/setting'));
      }
      // }
    },

    *getCaptcha({ payload }, { call }) {
      yield call(getFakeCaptcha, payload);
    },

    *logout(_, { put }) {
      yield put({
        type: 'changeLoginStatus',
        payload: {},
      });
      reloadAuthorized();
      localStorage.removeItem('currentUser');
      const { redirect } = getPageQuery();
      if (window.location.pathname !== '/user/login' && !redirect) {
        yield put(
          routerRedux.push({
            pathname: '/user/login',
            search: stringify({
              redirect: window.location.href,
            }),
          })
        );
      }
    },
  },

  reducers: {
    changeLoginStatus(state, { payload }) {
      setAuthorityByToken(payload);
      return {
        ...state,
        status: 'ok', // payload.status,
        // type: payload.type,
      };
    },
  },
};

import fetch from 'dva/fetch';
import { notification } from 'antd';
import { getStatusMessage } from '@http-status-codes/i18n-zh';
// import router from 'umi/router';
import hash from 'hash.js';
import { isAntdPro } from './utils';
import { getToken } from './authority';

const checkStatus = async response => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  let errorText;

  try {
    errorText = getStatusMessage(response.status);
  } catch (error) {
    errorText = formatMessage({ id: `request.${response.status}` }) || response.statusText;
  }

  const error = new Error(errorText);
  error.name = response.status;

  try {
    error.response = await response.json();
  } catch (err) {
    error.response = {
      error: err,
    };
  }

  // 目前仅对 500 做处理，400的状态码被后端进行了特殊使用，比如表单验证...
  const finalMessage = response.status < 500 ? error.response?.message : errorText;
  // 错误弹窗
  notification.error({
    message: finalMessage || errorText,
    maxCount: 3,
    duration: 2,
    key: response.status,
  });
  throw error;
};

const cachedSave = (
  response
  // hashcode
) => {
  /**
   * Clone a response data and store it in sessionStorage
   * Does not support data other than json, Cache only json
   */
  const contentType = response.headers.get('Content-Type');
  if (contentType && contentType.match(/application\/json/i)) {
    // All data is saved as text
    response.clone().text();
    // .then(content => {
    //   sessionStorage.setItem(hashcode, content);
    //   sessionStorage.setItem(`${hashcode}:timestamp`, Date.now());
    // });
  }
  return response;
};

/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [option] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */
export default function request(url, option) {
  const options = {
    expirys: isAntdPro(),
    ...option,
  };
  /**
   * Produce fingerprints based on url and parameters
   * Maybe url has the same parameters
   */
  const fingerprint = url + (options.body ? JSON.stringify(options.body) : '');
  const hashcode = hash
    .sha256()
    .update(fingerprint)
    .digest('hex');
  const token = getToken();
  const defaultOptions = {
    credentials: 'include',
    headers: {
      Accept: 'application/json',
    },
  };
  if (token && token.access_token) {
    defaultOptions.headers.Authorization = `Bearer ${token.access_token}`;
  }
  const newOptions = { ...defaultOptions, ...options };
  if (
    newOptions.method === 'POST' ||
    newOptions.method === 'PUT' ||
    newOptions.method === 'DELETE'
  ) {
    if (!(newOptions.body instanceof FormData)) {
      newOptions.headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
        ...newOptions.headers,
      };
      newOptions.body = JSON.stringify(newOptions.body);
    } else {
      // newOptions.body is FormData
      newOptions.headers = {
        Accept: 'application/json',
        // 'Content-Type': 'multipart/form-data',
        ...newOptions.headers,
      };
    }
  }

  const expirys = options.expirys && 60;
  // options.expirys !== false, return the cache,
  if (options.expirys !== false) {
    const cached = sessionStorage.getItem(hashcode);
    const whenCached = sessionStorage.getItem(`${hashcode}:timestamp`);
    if (cached !== null && whenCached !== null) {
      const age = (Date.now() - whenCached) / 1000;
      if (age < expirys) {
        const response = new Response(new Blob([cached]));
        return response.json();
      }
      sessionStorage.removeItem(hashcode);
      sessionStorage.removeItem(`${hashcode}:timestamp`);
    }
  }
  return fetch(url, newOptions)
    .then(checkStatus)
    .then(response => cachedSave(response, hashcode))
    .then(response => {
      // DELETE and 204 do not return data by default
      // using .json will report an error.
      if (newOptions.method === 'DELETE') {
        return response.text();
      }
      if (response.status === 204) {
        return response.status;
      }
      return response.text().then(text => {
        if (text.length > 0) {
          return JSON.parse(text);
        }
        return '';
      });
      // return response.json();
    })
    .catch(e => {
      const status = e.name;
      if (status === 401) {
        // @HACK
        /* eslint-disable no-underscore-dangle */
        window.g_app._store.dispatch({
          type: 'login/logout',
        });
      }
      throw e.response;
      // environment should not be used
      // if (status === 403) {
      //   router.push('/exception/403');
      //   return;
      // }

      // if (status >= 404 && status < 422) {
      //   router.push('/exception/404');
      // }
    });
}

// 发起 GET 请求
request.get = function get(url, params) {
  return request(url, {
    method: 'GET',
    params,
  });
};

// 发起 POST 请求
request.post = function post(url, data) {
  return request(url, {
    method: 'POST',
    body: data,
  });
};

// 发起 PUT 请求
request.put = function put(url, data) {
  return request(url, {
    method: 'PUT',
    body: data,
  });
};

// 发起 DELETE 请求
request.delete = function del(url, data = {}) {
  return request(url, {
    method: 'DELETE',
    body: data,
  });
};

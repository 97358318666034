// import analysis from './en-US/analysis';
// import exception from './en-US/exception';
// import form from './en-US/form';
// import globalHeader from './en-US/globalHeader';
// import login from './en-US/login';
// import menu from './en-US/menu';
// import monitor from './en-US/monitor';
// import result from './en-US/result';
// import settingDrawer from './en-US/settingDrawer';
// import settings from './en-US/settings';
// import pwa from './en-US/pwa';
// import table from './en-US/table';
// import merchant from './en-US/merchant';

// export default {
//   'navBar.lang': 'Languages',
//   'layout.user.link.help': 'Help',
//   'layout.user.link.privacy': 'Privacy',
//   'layout.user.link.terms': 'Terms',
//   'app.home.introduce': 'introduce',
//   'app.forms.basic.title': 'Basic form',
//   'app.forms.basic.description':
//     'Form pages are used to collect or verify information to users, and basic forms are common in scenarios where there are fewer data items.',
//   ...analysis,
//   ...exception,
//   ...form,
//   ...globalHeader,
//   ...login,
//   ...menu,
//   ...monitor,
//   ...result,
//   ...settingDrawer,
//   ...settings,
//   ...pwa,
//   ...table,
//   ...merchant
// };
import analysis from './zh-CN/analysis';
import exception from './zh-CN/exception';
import form from './zh-CN/form';
import globalHeader from './zh-CN/globalHeader';
import login from './zh-CN/login';
import menu from './zh-CN/menu';
import monitor from './zh-CN/monitor';
import result from './zh-CN/result';
import settingDrawer from './zh-CN/settingDrawer';
import settings from './zh-CN/settings';
import pwa from './zh-CN/pwa';
import table from './zh-CN/table';
import merchant from './zh-CN/merchant'
import transactionRecord from './en-US/transactionRecord'

export default {
  'navBar.lang': '语言',
  'layout.user.link.help': '帮助',
  'layout.user.link.privacy': '隐私',
  'layout.user.link.terms': '条款',
  'app.home.introduce': '介绍',
  'app.forms.basic.title': '基础表单',
  'app.forms.basic.description':
    '表单页用于向用户收集或验证信息，基础表单常见于数据项较少的表单场景。',
  ...analysis,
  ...exception,
  ...form,
  ...globalHeader,
  ...login,
  ...menu,
  ...monitor,
  ...result,
  ...settingDrawer,
  ...settings,
  ...pwa,
  ...table,
  ...merchant,
  ...transactionRecord
};

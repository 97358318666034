import { query as queryUsers, queryCurrent, changePassword, update, query } from '@/services/user';
import { setAuthorityByApi } from '@/utils/authority';
import { reloadAuthorized } from '@/utils/Authorized';
import { getCacheWithExpiryTime, setCacheWithExpiryTime } from '@/utils/utils';

export default {
  namespace: 'user',

  state: {
    list: [],
    currentUser: {},
    permissionAction: {},
  },

  effects: {
    *fetch(_, { call, put }) {
      const response = yield call(queryUsers);
      yield put({
        type: 'save',
        payload: response,
      });
    },
    *fetchCurrent({ payload, forceRefresh = false }, { call, put }) {
      let response = payload;
      if (!forceRefresh) {
        // 尝试从缓存中读取数据
        const cachedUser = getCacheWithExpiryTime('currentUser');
        if (cachedUser) {
          // 如果缓存中有数据，直接使用缓存的数据
          response = JSON.parse(cachedUser);
        }
      }
      if (!response) {
        // 如果缓存中没有数据或强制刷新，从请求中获取
        response = yield call(queryCurrent);
        // 将获取到的数据放到缓存中
        setCacheWithExpiryTime('currentUser', JSON.stringify(response));
        setAuthorityByApi(response.permission);
        reloadAuthorized();
      }
      // 将数据保存到 state 中
      yield put({
        type: 'saveCurrentUser',
        payload: response,
      });
    },
    *update({ payload }, { call, put }) {
      // console.log(payload);
      const response = yield call(update, 'me', '', payload);
      // console.log(response);
      yield put({
        type: 'save',
        payload: response,
      });
      // if(callback) callback();
    },
    *changePassword({ payload }, { call }) {
      const response = yield call(changePassword, payload);
      return response;
    },
    *fetchDetail({ payload }, { call, put }) {
      const response = yield call(query, `me`, '', payload);
      yield put({
        type: 'saveDetail',
        payload: response,
      });
    },
  },

  reducers: {
    save(state, action) {
      return {
        ...state,
        list: action.payload,
      };
    },
    saveDetail(state, action) {
      return {
        ...state,
        customer: action.payload,
      };
    },
    saveCurrentUser(state, action) {
      localStorage.setItem('PAY_CLOAK_USER_NAME', action.payload.email);
      return {
        ...state,
        permissionAction: action.payload.permissionAction,
        currentUser:
          {
            ...action.payload,
            avatar: 'https://gw.alipayobjects.com/zos/rmsportal/BiazfanxmamNRoxxVxka.png',
          } || {},
      };
    },
    changeNotifyCount(state, action) {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          notifyCount: action.payload.totalCount,
          unreadCount: action.payload.unreadCount,
        },
      };
    },
  },
};

import request from '@/utils/request';

export async function query() {
  return request('/api/me');
}

export async function queryCurrent() {
  return request('/api/me');
}

// export async function changePSW() {
//   return request('/api/reset');
// }

export async function changePassword(params) {
  return request('/api/me/password/change', {
    method: 'POST',
    body: {
      ...params,
    },
  });
}

export async function update(resource, id, params) {
  return request(`/api/${resource}`, {
    method: 'POST',
    body: {
      ...params,
    },
  });
}

export const dva = {
  config: {
    onError() {
      // e.preventDefault();
      // console.error('error', e.response);
    },
  },
  plugins: [],
};
export default dva;

export function onRouteChange({ location }) {
  // !NOTE 以下是在路由切换时进行埋点
  if (localStorage.getItem(`PAY_CLOAK_TRACKED_PATH_NAME: ${location.pathname}`)) {
    return;
  }

  localStorage.setItem(`PAY_CLOAK_TRACKED_PATH_NAME: ${location.pathname}`, true);
  setTimeout(() => {
    window.lfq('track', 'pageView');
    localStorage.removeItem(`PAY_CLOAK_TRACKED_PATH_NAME: ${location.pathname}`, true);
  }, 1000);
}

import moment from 'moment';
import React from 'react';
import CryptoJS from 'crypto-js';
import nzh from 'nzh/cn';
import { parse, stringify } from 'qs';
import cloneDeep from 'lodash/cloneDeep';
import isNil from 'lodash/isNil';
import CustomPagination from '@/components/CustomPagination/index.jsx';

import { getAlioss } from '@/services/api';

const OSS = require('ali-oss');

export function fixedZero(val) {
  return val * 1 < 10 ? `0${val}` : val;
}

export function getTimeDistance(type) {
  const now = new Date();
  const oneDay = 1000 * 60 * 60 * 24;

  if (type === 'today') {
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    return [moment(now), moment(now.getTime() + (oneDay - 1000))];
  }

  if (type === 'week') {
    let day = now.getDay();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);

    if (day === 0) {
      day = 6;
    } else {
      day -= 1;
    }

    const beginTime = now.getTime() - day * oneDay;

    return [moment(beginTime), moment(beginTime + (7 * oneDay - 1000))];
  }

  if (type === 'month') {
    const year = now.getFullYear();
    const month = now.getMonth();
    const nextDate = moment(now).add(1, 'months');
    const nextYear = nextDate.year();
    const nextMonth = nextDate.month();

    return [
      moment(`${year}-${fixedZero(month + 1)}-01 00:00:00`),
      moment(moment(`${nextYear}-${fixedZero(nextMonth + 1)}-01 00:00:00`).valueOf() - 1000),
    ];
  }

  const year = now.getFullYear();
  return [moment(`${year}-01-01 00:00:00`), moment(`${year}-12-31 23:59:59`)];
}

export function getPlainNode(nodeList, parentPath = '') {
  const arr = [];
  nodeList.forEach(node => {
    const item = node;
    item.path = `${parentPath}/${item.path || ''}`.replace(/\/+/g, '/');
    item.exact = true;
    if (item.children && !item.component) {
      arr.push(...getPlainNode(item.children, item.path));
    } else {
      if (item.children && item.component) {
        item.exact = false;
      }
      arr.push(item);
    }
  });
  return arr;
}

export function digitUppercase(n) {
  return nzh.toMoney(n);
}

function getRelation(str1, str2) {
  if (str1 === str2) {
    console.warn('Two path are equal!'); // eslint-disable-line
  }
  const arr1 = str1.split('/');
  const arr2 = str2.split('/');
  if (arr2.every((item, index) => item === arr1[index])) {
    return 1;
  }
  if (arr1.every((item, index) => item === arr2[index])) {
    return 2;
  }
  return 3;
}

function getRenderArr(routes) {
  let renderArr = [];
  renderArr.push(routes[0]);
  for (let i = 1; i < routes.length; i += 1) {
    // 去重
    renderArr = renderArr.filter(item => getRelation(item, routes[i]) !== 1);
    // 是否包含
    const isAdd = renderArr.every(item => getRelation(item, routes[i]) === 3);
    if (isAdd) {
      renderArr.push(routes[i]);
    }
  }
  return renderArr;
}

/**
 * Get router routing configuration
 * { path:{name,...param}}=>Array<{name,path ...param}>
 * @param {string} path
 * @param {routerData} routerData
 */
export function getRoutes(path, routerData) {
  let routes = Object.keys(routerData).filter(
    routePath => routePath.indexOf(path) === 0 && routePath !== path
  );
  // Replace path to '' eg. path='user' /user/name => name
  routes = routes.map(item => item.replace(path, ''));
  // Get the route to be rendered to remove the deep rendering
  const renderArr = getRenderArr(routes);
  // Conversion and stitching parameters
  const renderRoutes = renderArr.map(item => {
    const exact = !routes.some(route => route !== item && getRelation(route, item) === 1);
    return {
      exact,
      ...routerData[`${path}${item}`],
      key: `${path}${item}`,
      path: `${path}${item}`,
    };
  });
  return renderRoutes;
}

export function getPageQuery() {
  return parse(window.location.href.split('?')[1]);
}

export function getQueryPath(path = '', query = {}) {
  const search = stringify(query);
  if (search.length) {
    return `${path}?${search}`;
  }
  return path;
}

/* eslint no-useless-escape:0 */
const reg = /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;

export function isUrl(path) {
  return reg.test(path);
}

export function formatWan(val) {
  const v = val * 1;
  if (!v || Number.isNaN(v)) return '';

  let result = val;
  if (val > 10000) {
    result = Math.floor(val / 10000);
    result = (
      <span>
        {result}
        <span
          styles={{
            position: 'relative',
            top: -2,
            fontSize: 14,
            fontStyle: 'normal',
            lineHeight: 20,
            marginLeft: 2,
          }}
        >
          万
        </span>
      </span>
    );
  }
  return result;
}

// 给官方演示站点用，用于关闭真实开发环境不需要使用的特性
export function isAntdPro() {
  return window.location.hostname === 'preview.pro.ant.design';
}

/**
 * 将约束条件转换成laravel-query-builder要求的格式
 */
export function formatCriteria(fields) {
  const formatted = {};
  Object.keys(fields).forEach(key => {
    const newKey = key.replace('-', '.');
    formatted[newKey] = fields[key];
    if (fields[key] instanceof moment) {
      formatted[key] = fields[key].format('YYYY-MM-DD');
    }
    if (formatted[key] instanceof String || typeof formatted[key] === 'string') {
      formatted[key] = formatted[key].trim();
    }

    // console.log('item', key, formatted[key])
  });
  return formatted;
}

export const getValue = obj =>
  Object.keys(obj)
    .map(key => obj[key])
    .join(',');

// 日期选择器预设选择范围
export const dateRanges = {
  今日: [moment().startOf('day'), moment().endOf('day')],
  昨日: [
    moment()
      .subtract(1, 'days')
      .startOf('day'),
    moment()
      .subtract(1, 'days')
      .endOf('day'),
  ],
  最近5天: [
    moment()
      .startOf('day')
      .subtract(4, 'days'),
    moment().endOf('day'),
  ],
  最近7天: [
    moment()
      .startOf('day')
      .subtract(6, 'days'),
    moment().endOf('day'),
  ],
  最近15天: [
    moment()
      .startOf('day')
      .subtract(14, 'days'),
    moment().endOf('day'),
  ],
  最近30天: [
    moment()
      .startOf('day')
      .subtract(29, 'days'),
    moment().endOf('day'),
  ],
};

// 获取过去某一天的日期
export const getDate = day => {
  const date = moment()
    .startOf('day')
    .subtract(day, 'days')
    .format('YYYY-MM-DD');
  return date;
};

export const storeDomainMap = {
  shopify: 'SP',
  wshop: 'WS',
  shopbase: 'SB',
  xshoppy: 'XS',
  myshopify: 'SP',
  wshopon: 'WS',
  onshopbase: 'SB',
  mynodeshop: 'NS',
  nodeshop: 'NS',
  hotishop: 'MS',
  mshop: 'MS',
};

export const handlePlatform = domain => {
  if (!domain) return '';
  domain = domain.replace('.com', '').replace('.shop', ''); //eslint-disable-line
  const index = domain.indexOf('.');
  if (index !== -1) return domain.substring(index + 1, domain.length);
  return '';
};

// 生成uuid
export const generateUuid = () =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    // eslint-disable-next-line no-bitwise
    const r = (Math.random() * 16) | 0;
    // eslint-disable-next-line no-bitwise
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });

// 获取完整domain
export const getFullDomainNode = (domain, displayDomain, isToFront) => {
  const suffix = isToFront ? '' : '/admin';
  const realUrlDomain = domain
    ? `//${domain.replace('https://', '').replace('http://', '')}${suffix}`
    : '';
  return (
    <a type="link" href={realUrlDomain} target="_blank" rel="noopener noreferrer">
      {displayDomain}
    </a>
  );
};

export const handleDomainName = domain => {
  if (!domain) return '';
  return domain
    .replace('.myshopify.com', '')
    .replace('.wshopon.com', '')
    .replace('.xshoppy.shop', '')
    .replace('.onshopbase.com', '')
    .replace('.frp.codefriend.top', '')
    .replace('.mynodeshop.com', '')
    .replace('.hotishop.com', '');
};

export const getDatePicker = (dateList, dateName) => {
  const date = {};
  if (dateList) {
    if (dateList[0]) {
      date[dateName[0]] = moment(dateList[0]).format('YYYY-MM-DD');
    }
    if (dateList[1]) {
      date[dateName[1]] = moment(dateList[1]).format('YYYY-MM-DD');
    }
  }
  return date;
};

export const getPagination = (
  totalCount,
  pageLoading,
  currentPage,
  pageNumber,
  handlePageChange
) => {
  const pagination = () => (
    <CustomPagination
      total={totalCount}
      loading={pageLoading}
      currentPage={Number(currentPage)}
      pageSize={pageNumber}
      options={['10', '30', '50', '100', '200', '500']}
      handlePageChange={handlePageChange}
      handleSizeChange={handlePageChange}
    />
  );
  return pagination();
};

export const returnIsSW = (shop_url, shop_name) => {
  const shopUrl = shop_url || '';
  const Reg = /（(.+?)）/gi;
  const shopName =
    shop_name && typeof shop_name === 'string'
      ? (shop_name.match(Reg) && shop_name.match(Reg)[0]) ?? ''
      : '';
  // 商店url包含hotishop.com并且商店部门不是TEST或者XLQ
  const isSW =
    shopUrl.includes('hotishop.com') && !(shopName.includes('TEST') || shopName.includes('XLQ'));
  return isSW;
};

export const returnOssUrl = (url = '') =>
  `https://sz-paycloak-apps.oss-cn-shenzhen.aliyuncs.com/${url}`;

// 获取oss的Token
export const getOssToken = async () => {
  const ossClient = sessionStorage.getItem('ossClient');
  if (ossClient) {
    const token = JSON.parse(ossClient);
    if (token?.Expiration && moment().isBefore(token?.Expiration)) {
      return token;
    }
  }

  const token = await getAlioss();
  sessionStorage.setItem('ossClient', JSON.stringify(token));
  return token;
};

// 获取oss的sts临时令牌
export const getOssClient = async () => {
  const getOss = token =>
    new OSS({
      // yourRegion填写Bucket所在地域。以华东1（杭州）为例，yourRegion填写为oss-cn-hangzhou。
      region: 'oss-cn-shenzhen',
      accessKeyId: token.AccessKeyId,
      accessKeySecret: token.AccessKeySecret,
      stsToken: token.SecurityToken,
      // 填写Bucket名称，例如examplebucket。
      bucket: token.bucket,
      // 刷新临时访问凭证。
      refreshSTSToken: async () => {
        const refreshToken = await getAlioss();
        sessionStorage.setItem('ossClient', JSON.stringify(token));
        return {
          accessKeyId: refreshToken.AccessKeyId,
          accessKeySecret: refreshToken.AccessKeySecret,
          stsToken: refreshToken.SecurityToken,
        };
      },
    });

  const token = await getOssToken();
  return getOss(token);
};

// 格式化为filter-new
export const formatTableParamsNew = params => {
  const filter = {};
  Object.keys(params).forEach(key => {
    switch (key) {
      case 'sorter':
        filter[params[key].key] = params[key]?.value;
        break;
      case 'current':
        filter.current_page = params[key];
        break;
      case 'pageSize':
        filter.per_page = params[key];
        break;
      case 'total':
        break;
      default:
        filter[`filter[${key}]`] = params[key];
        break;
    }
  });
  return filter;
};
// 格式化为filter-dachun
export const formatTableParams = params => {
  const filter = {};
  Object.keys(params).forEach(key => {
    switch (key) {
      case 'sorter':
        filter[params[key].key] = params[key]?.value;
        break;
      case 'current':
        filter.page = params[key];
        break;
      case 'pageSize':
        filter.per_page = params[key];
        break;
      case 'total':
        break;
      default:
        filter[`filter[${key}]`] = params[key];
        break;
    }
  });
  return filter;
};

// 格式化为filter
export const formatTableParamsPage = params => {
  const filter = {};
  Object.keys(params).forEach(key => {
    switch (key) {
      case 'sorter':
        filter[params[key].key] = params[key]?.value;
        break;
      case 'current':
        filter.page = params[key];
        break;
      case 'pageSize':
        filter.per_page = params[key];
        break;
      case 'total':
        break;
      default:
        filter[key] = params[key];
        break;
    }
  });
  return filter;
};

// 清除空字符串、null和undefined
export const clearFalseValue = compactParam => {
  const compactParamData = cloneDeep(compactParam);
  // eslint-disable-next-line no-restricted-syntax
  for (const key in compactParamData) {
    if (
      isNil(compactParamData[key]) ||
      compactParamData[key] === '' ||
      (Array.isArray(compactParamData[key]) && compactParamData[key].length === 0)
    ) {
      delete compactParamData[key];
    }
  }
  return compactParamData;
};

export const certificateType = [
  '环贸',
  '斑头雁',
  '云途',
  'UBI',
  '万邦',
  '新树',
  '燕文',
  '斑头雁V2.0',
  '深圳市维央国际',
  '深圳市维央国际(金华)',
  '深圳七千里国际物流有限公司',
  '上海义达国际物流有限公司',
  '万通物流(湛美)',
  '浙江闪电猴供应链管理有限公司',
  '网易速达EQUICK',
  '急速国际（新）',
  'ITDIDA',
];

export const faceOrderType = [
  '美国专线小包-轻小件',
  '美国专线小包-敏感',
  '安小包-美国专线（特惠带电）',
  '云途全球专线挂号（特惠普货）',
  '联邮通经济挂号-普货',
];

export const formatTableDataSource = response => ({
  data: response.data,
  success: true,
  total: response?.total || 0,
  current: response?.current_page || 0,
  pageSize: response?.per_page || 0,
});
// 计算文件的MD5哈希值
export const calculateMD5 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = e => {
      const arrayBufferView = e.target.result;
      // 将ArrayBuffer转换为WordArray对象
      const wordArray = CryptoJS.lib.WordArray.create(arrayBufferView);

      // 计算MD5哈希值
      const md5Hash = CryptoJS.MD5(wordArray).toString();

      resolve(md5Hash.toUpperCase());
    };
    reader.onerror = e => reject(e);
    reader.readAsArrayBuffer(file);
  });

// 格式化为filter 大春新规则4月19日
export const formatNewTableParams = params => {
  const filter = {};
  Object.keys(params).forEach(key => {
    switch (key) {
      case 'sorter':
        filter[params[key].key] = params[key]?.value;
        break;
      case 'current':
        filter.current_page = params[key];
        break;
      case 'pageSize':
        filter.per_page = params[key];
        break;
      case 'total':
        break;
      default:
        filter[`filter[${key}]`] = params[key];
        break;
    }
  });
  return filter;
};
export const formatNewTableDataSource = response => ({
  data: response.data,
  success: true,
  total: response?.meta?.total || 0,
  current: response?.meta?.current_page || 0,
  pageSize: response?.meta?.per_page || 0,
});

// 获取指定范围内的随机过期时间
const getRandomExpiryTime = (minMinutes, maxMinutes) => {
  const minMinutesValue = JSON.parse(localStorage.getItem('minMinutes') || null);
  const maxMinutesValue = JSON.parse(localStorage.getItem('maxMinutes') || null);
  const minMs = (minMinutesValue === null ? minMinutes : minMinutesValue) * 60 * 1000;
  const maxMs = (maxMinutesValue === null ? maxMinutes : maxMinutesValue) * 60 * 1000;
  return Math.floor(Math.random() * (maxMs - minMs + 1)) + minMs;
};

// 设置缓存，带有过期时间
export const setCacheWithExpiryTime = (key, value, minMinutes = 45, maxMinutes = 75) => {
  const now = new Date();
  const expiryTime = now.getTime() + getRandomExpiryTime(minMinutes, maxMinutes);
  const item = {
    value,
    expiryTime,
  };
  localStorage.setItem(key, JSON.stringify(item));
};

// 判断缓存是否过期
export const isCacheExpired = key => {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return true;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  return now.getTime() > item.expiryTime;
};

// 获取缓存，如果缓存过期则删除缓存并返回null
export const getCacheWithExpiryTime = key => {
  if (isCacheExpired(key)) {
    localStorage.removeItem(key);
    return null;
  }

  const { value } = JSON.parse(localStorage.getItem(key));
  return value;
};

// 登录状态已经失效
export const isLoginExpired = () => {
  const expires = localStorage.getItem('api-token-expires');
  if (!expires) {
    return true;
  }

  if (moment(expires).isBefore(moment(new Date()))) {
    return true;
  }

  return false;
};

// 判断应用是否在前台（可见）
export const isAppVisible = () => {
  // 获取正确的前缀
  let prefix = 'hidden';
  if (typeof document[prefix] === 'undefined') {
    // 为不同的浏览器获取正确的前缀
    // eslint-disable-next-line no-restricted-syntax
    for (prefix in { mozHidden: 'moz', msHidden: 'ms', oHidden: 'o', webkitHidden: 'webkit' }) {
      if (document[`${prefix}`] !== undefined) {
        document.hidden = document[prefix];
        break;
      }
    }
  }

  return !document.hidden;
};
